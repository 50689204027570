<template>
    <div>
        <highcharts
            style="min-height: 250px"
            :options="chartOptions"
        ></highcharts>
    </div>
</template>

<script>
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import axios from "axios";

Highcharts.setOptions({
    lang: {
        decimalPoint: ".",
        thousandsSep: ",",
    },
});
export default {
    name: "BarChart",
    components: {
        highcharts: HighchartsVue.component,
    },
    props: {
        type_chart: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    type: "bar",
                    scrollablePlotArea: {
                        minHeight: 200,
                    },
                    marginRight: 30,
                },
                title: {
                    text: "",
                },
                xAxis: {
                    categories: [],
                    labels: {
                        // step: 1, // แสดงทุกป้าย
                        autoRotation: [-45, -90], // ปิดการหมุนอัตโนมัติ
                        // useHTML: true,
                        // style: {
                        //   width: "70px", // กำหนดความกว้างของ label แต่ละตัว
                        //   overflow: "hidden", // ซ่อนข้อความที่เกิน
                        // },
                        // formatter: function () {
                        //     // แทรก '\n' สำหรับการขึ้นบรรทัดใหม่ระหว่างข้อความ
                        //     // ถ้าข้อความยาวเกิน 5 ตัวอักษร ให้ย่อข้อความ
                        //     if (this.value.length > 15) {
                        //         return this.value.substring(0, 15) + "..";
                        //     } else {
                        //         return this.value;
                        //     }
                        // },
                    },
                    tickInterval: 1, // ตั้งค่าระยะห่างระหว่าง ticks
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: "ยอดขาย (บาท)",
                    },
                },
                plotOptions: {
                    series: {
                        pointPadding: 0.1, // ระยะห่างระหว่างแท่งในกลุ่ม
                        groupPadding: 0.1, // ระยะห่างระหว่างกลุ่มแท่ง
                        borderWidth: 0,
                    },
                    bar: {
                        dataLabels: {
                            enabled: true,
                            inside: true,
                            color: "#000",
                            formatter: function () {
                                return this.y?.toLocaleString();
                                // if (this.y >= 1000000) {
                                //     return (this.y / 1000000).toFixed(1) + "M "; // แสดงผลเป็น M
                                // } else if (this.y >= 1000) {
                                //     return (this.y / 1000).toFixed(0) + "k"; // แสดงผลเป็น K
                                // } else {
                                //     return this.y; // แสดงตัวเลขปกติหากน้อยกว่า 1000
                                // }
                            },
                        },
                    },
                },
                legend: {
                    enabled: false,
                },
                // tooltip: {
                //     pointFormat: "ยอดขาย: <b>{point.y} </b>",
                // },
                series: [
                    {
                        name: "",
                        colors: [
                            "#EB5B00",
                            "#FFB200",
                            "#B60071",
                            "#FF4C4C",
                            "#FFB22C",
                            "#FFDE4D",
                            "#FFAD60",
                            "#FFBF78",
                            "#FEB941",
                        ],
                        colorByPoint: true,
                        data: [0, 0, 0, 0],
                    },
                ],
                exporting: {
                    enabled: false,
                },
                credits: {
                    enabled: false,
                },
            },
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            // console.log("Chart Type:", this.type_chart);
            try {
                const community_id = localStorage.getItem("community_id");
                const role_id = localStorage.getItem("role_id");
                let categories = [];
                let values = [];
                const type_data = this.type_chart;
                const apiData = [];
                // const id = localStorage.getItem("id");

                // ยอดขายแยกตามกิจกรรมของชุมชน (บาท)
                if (type_data == "active") {
                    if (role_id == 1) {
                        const res = await axios.get(
                            "https://openapi.kims-rmuti.com/api/activitycom",
                        );
                        apiData.push(...res.data);
                    } else {
                        const res = await axios.get(
                            `https://openapi.kims-rmuti.com/api/activitycom?community=${community_id}`,
                        ); // เปลี่ยน URL ตาม API ของคุณ
                        apiData.push(...res.data);
                    }
                    
                    // console.log("apiData", apiData);
                    const sort_data = apiData
                        ?.filter((el) => el.activity_price != "")
                        .sort((a, b) =>
                            parseInt(b.activity_price)
                                ? parseInt(b.activity_price)
                                : 0 - parseInt(a.activity_price)
                                  ? parseInt(a.activity_price)
                                  : 0,
                        );
                    // ตัวอย่างการจัดการข้อมูล
                    categories = sort_data.map((item) => item.activity_name); // เช่น ['Jan', 'Feb', 'Mar']
                    values = sort_data.map((item) =>
                        parseInt(item.activity_price),
                    ); // เช่น [10, 20, 30]
                }
                // ยอดขายแยกตามเส้นทางการท่องเที่ยว (บาท)
                else if (type_data == "route") {
                    if (role_id == 1) {
                        const res = await axios.get(
                            "https://openapi.kims-rmuti.com/api/TourismRoute",
                        );
                        apiData.push(...res.data);
                    } else {
                        const res = await axios.get(
                            `https://openapi.kims-rmuti.com/api/TourismRoute?community=${community_id}`,
                        ); // เปลี่ยน URL ตาม API ของคุณ
                        apiData.push(...res.data);
                    }
                    // จัดกลุ่มและรวม activity_price
                    const groupedData = apiData.reduce((acc, item) => {
                        const routeName = item.tourism_main_route_name;
                        const price = parseInt(item.activity_price)
                            ? parseInt(item.activity_price)
                            : 0;

                        if (!acc[routeName]) {
                            acc[routeName] = {
                                tourism_main_route_name: routeName,
                                tourism_route_group_name:
                                    item.tourism_route_group_name,
                                image: item.image,
                                total_price: 0,
                            };
                        }

                        acc[routeName].total_price += price;
                        return acc;
                    }, {});

                    // แปลงกลับเป็น Array
                    const result = Object.values(groupedData);
                    const sort_data = result
                        ?.filter((el) => el.total_price != "")
                        .sort((a, b) => b.total_price - a.total_price);
                    // ตัวอย่างการจัดการข้อมูล
                    categories = sort_data.map(
                        (item) => item.tourism_main_route_name,
                    ); // เช่น ['Jan', 'Feb', 'Mar']
                    values = sort_data.map((item) =>
                        parseInt(item.total_price),
                    ); // เช่น [10, 20, 30]
                }
                // ยอดขายแยกตามโปรแกรมการท่องเที่ยว (บาท)
                else if (type_data == "program") {
                    if (role_id == 1) {
                        const res = await axios.get(
                            "https://openapi.kims-rmuti.com/api/ProgramTour",
                        );
                        apiData.push(...res.data);
                    } else {
                        const res = await axios.get(
                            `https://openapi.kims-rmuti.com/api/ProgramTour?community=${community_id}`,
                        ); // เปลี่ยน URL ตาม API ของคุณ
                        apiData.push(...res.data);
                    }
                    // จัดกลุ่มและรวม activity_price
                    const groupedData = apiData.reduce((acc, item) => {
                        const routeName = item.tourism_sub_route_name;
                        const price = parseInt(item.activity_price)
                            ? parseInt(item.activity_price)
                            : 0;

                        if (!acc[routeName]) {
                            acc[routeName] = {
                                tourism_sub_route_name: routeName,
                                total_price: 0,
                            };
                        }

                        acc[routeName].total_price += price;
                        return acc;
                    }, {});

                    // แปลงกลับเป็น Array
                    const result = Object.values(groupedData);
                    const sort_data = result
                        ?.filter((el) => el.total_price != "")
                        .sort((a, b) => b.total_price - a.total_price);
                    // ตัวอย่างการจัดการข้อมูล
                    categories = sort_data.map(
                        (item) => item.tourism_sub_route_name,
                    ); // เช่น ['Jan', 'Feb', 'Mar']
                    values = sort_data.map((item) =>
                        parseInt(item.total_price),
                    ); // เช่น [10, 20, 30]
                }
                // ยอดขายแยกตามไกด์ท้องถิ่น (บาท)
                else if (type_data == "guide") {
                    if (role_id == 1) {
                        const res = await axios.get(
                            "https://openapi.kims-rmuti.com/api/GuideTour",
                        );
                        apiData.push(...res.data);
                    } else {
                        const res = await axios.get(
                            `https://openapi.kims-rmuti.com/api/GuideTour?community=${community_id}`,
                        ); // เปลี่ยน URL ตาม API ของคุณ
                        apiData.push(...res.data);
                    }
                    const groupedData = apiData.reduce((acc, item) => {
                        const routeName = item.people_name;
                        if (!acc[routeName]) {
                            acc[routeName] = {
                                people_name: routeName,
                                total_price: 0,
                            };
                        }

                        acc[routeName].total_price += 1;
                        return acc;
                    }, {});

                    // แปลงกลับเป็น Array
                    const result = Object.values(groupedData);
                    // console.log("apiData", apiData);
                    const sort_data = result.sort(
                        (a, b) => b.total_price - a.total_price,
                    );
                    // ตัวอย่างการจัดการข้อมูล
                    categories = sort_data.map((item) => item.people_name); // เช่น ['Jan', 'Feb', 'Mar']
                    values = sort_data.map((item) =>
                        parseInt(item.total_price),
                    ); // เช่น [10, 20, 30]
                    this.chartOptions.yAxis.title.text = "";
                }
                // ยอดขายแยกตามที่พัก (บาท)
                else if (type_data == "hotel") {
                    if (role_id == 1) {
                        const res = await axios.get(
                            "https://openapi.kims-rmuti.com/api/hotel",
                        );
                        apiData.push(...res.data);
                    } else {
                        const res = await axios.get(
                            `https://openapi.kims-rmuti.com/api/hotel?community=${community_id}`,
                        ); // เปลี่ยน URL ตาม API ของคุณ
                        apiData.push(...res.data);
                    }
                    // จัดกลุ่มและรวม activity_price
                    const groupedData = apiData.reduce((acc, item) => {
                        const routeName = item.hotel_name;
                        const price = parseInt(item.hotel_room_price_max)
                            ? parseInt(item.hotel_room_price_max)
                            : 0;

                        if (!acc[routeName]) {
                            acc[routeName] = {
                                hotel_name: routeName,
                                total_price: 0,
                            };
                        }

                        acc[routeName].total_price += price;
                        return acc;
                    }, {});

                    // แปลงกลับเป็น Array
                    const result = Object.values(groupedData);
                    const sort_data = result
                        ?.filter((el) => el.total_price != "")
                        .sort((a, b) => b.total_price - a.total_price);
                    // ตัวอย่างการจัดการข้อมูล
                    categories = sort_data.map((item) => item.hotel_name); // เช่น ['Jan', 'Feb', 'Mar']
                    values = sort_data.map((item) =>
                        parseInt(item.total_price),
                    ); // เช่น [10, 20, 30]
                }
                // ยอดขายแยกตามร้านอาหาร (บาท)
                else if (type_data == "restaurant") {
                    if (role_id == 1) {
                        const res = await axios.get(
                            "https://openapi.kims-rmuti.com/api/restaurant",
                        );
                        apiData.push(...res.data);
                    } else {
                        const res = await axios.get(
                            `https://openapi.kims-rmuti.com/api/restaurant?community=${community_id}`,
                        ); // เปลี่ยน URL ตาม API ของคุณ
                        apiData.push(...res.data);
                    }
                    // จัดกลุ่มและรวม activity_price
                    const groupedData = apiData.reduce((acc, item) => {
                        const routeName = item.restaurant_name;
                        const price = parseInt(item.restaurant_price_max)
                            ? parseInt(item.restaurant_price_max)
                            : 0;

                        if (!acc[routeName]) {
                            acc[routeName] = {
                                hotel_name: routeName,
                                total_price: 0,
                            };
                        }

                        acc[routeName].total_price += price;
                        return acc;
                    }, {});

                    // แปลงกลับเป็น Array
                    const result = Object.values(groupedData);
                    const sort_data = result
                        ?.filter((el) => el.total_price != "")
                        .sort((a, b) => b.total_price - a.total_price);
                    // ตัวอย่างการจัดการข้อมูล
                    categories = sort_data.map((item) => item.hotel_name); // เช่น ['Jan', 'Feb', 'Mar']
                    values = sort_data.map((item) =>
                        parseInt(item.total_price),
                    ); // เช่น [10, 20, 30]
                }
                // ความสนใจสินค้า (คลิ๊ก)
                else if (type_data == "BehaviorProductCommunity") {
                    if (role_id == 1) {
                        const res = await axios.get(
                            "https://openapi.kims-rmuti.com/api/BehaviorProductCommunity",
                        );
                        apiData.push(...res.data);
                    } else {
                        const res = await axios.get(
                            `https://openapi.kims-rmuti.com/api/BehaviorProductCommunity?community=${community_id}`,
                        ); // เปลี่ยน URL ตาม API ของคุณ
                        apiData.push(...res.data);
                    }
                    // จัดกลุ่มและรวม activity_price
                    const groupedData = apiData.reduce((acc, item) => {
                        const routeName = item.product_name;

                        if (!acc[routeName]) {
                            acc[routeName] = {
                                product_name: routeName,
                                total_price: 0,
                            };
                        }

                        acc[routeName].total_price += 1;
                        return acc;
                    }, {});

                    // แปลงกลับเป็น Array
                    const result = Object.values(groupedData);
                    const sort_data = result
                        ?.filter((el) => el.total_price != "")
                        .sort((a, b) => b.total_price - a.total_price);
                    // ตัวอย่างการจัดการข้อมูล
                    categories = sort_data.map((item) => item.product_name); // เช่น ['Jan', 'Feb', 'Mar']
                    values = sort_data.map((item) =>
                        parseInt(item.total_price),
                    ); // เช่น [10, 20, 30]
                    this.chartOptions.yAxis.title.text =
                        "จำนวนการเข้าดู (คลิ๊ก)";
                }
                // ความต้องการสินค้า (ซื้อหรือจอง)
                else if (type_data == "Reservation") {
                    if (role_id == 1) {
                        const res = await axios.get(
                            "https://openapi.kims-rmuti.com/api/Reservation",
                        );
                        apiData.push(...res.data);
                    } else {
                        const res = await axios.get(
                            `https://openapi.kims-rmuti.com/api/Reservation?community=${community_id}`,
                        ); // เปลี่ยน URL ตาม API ของคุณ
                        apiData.push(...res.data);
                    }
                    // console.log("apiData", apiData);
                    const sort_data = apiData
                        ?.filter((el) => el.quantity_reserved != "")
                        .sort((a, b) =>
                            parseInt(b.quantity_reserved)
                                ? parseInt(b.quantity_reserved)
                                : 0 - parseInt(a.quantity_reserved)
                                  ? parseInt(a.quantity_reserved)
                                  : 0,
                        );
                    // ตัวอย่างการจัดการข้อมูล
                    categories = sort_data.map((item) => item.product_name); // เช่น ['Jan', 'Feb', 'Mar']
                    values = sort_data.map((item) =>
                        parseInt(item.quantity_reserved),
                    ); // เช่น [10, 20, 30]
                    this.chartOptions.yAxis.title.text = "";
                }
                // TODO รอAPI ความสนใจในบริการ (คลิ๊ก)
                else if (type_data == "service") {
                    // const response = await axios.get(
                    //     `https://openapi.kims-rmuti.com/api/Reservation?community=${community_id}`,
                    // ); // เปลี่ยน URL ตาม API ของคุณ
                    // const apiData = response.data;
                    const apiData = [];
                    // console.log("apiData", apiData);
                    const sort_data = apiData
                        ?.filter((el) => el.quantity_reserved != "")
                        .sort((a, b) =>
                            parseInt(b.quantity_reserved)
                                ? parseInt(b.quantity_reserved)
                                : 0 - parseInt(a.quantity_reserved)
                                  ? parseInt(a.quantity_reserved)
                                  : 0,
                        );
                    // ตัวอย่างการจัดการข้อมูล
                    categories = sort_data.map((item) => item.product_name); // เช่น ['Jan', 'Feb', 'Mar']
                    values = sort_data.map((item) =>
                        parseInt(item.quantity_reserved),
                    ); // เช่น [10, 20, 30]
                    this.chartOptions.yAxis.title.text = "";
                }
                // TODO รอAPI ความต้องการในบริการ (ซื้อหรือจอง)
                else if (type_data == "Reservation_service") {
                    // const response = await axios.get(
                    //     `https://openapi.kims-rmuti.com/api/Reservation?community=${community_id}`,
                    // ); // เปลี่ยน URL ตาม API ของคุณ
                    // const apiData = response.data;
                    const apiData = [];
                    // console.log("apiData", apiData);
                    const sort_data = apiData
                        ?.filter((el) => el.quantity_reserved != "")
                        .sort((a, b) =>
                            parseInt(b.quantity_reserved)
                                ? parseInt(b.quantity_reserved)
                                : 0 - parseInt(a.quantity_reserved)
                                  ? parseInt(a.quantity_reserved)
                                  : 0,
                        );
                    // ตัวอย่างการจัดการข้อมูล
                    categories = sort_data.map((item) => item.product_name); // เช่น ['Jan', 'Feb', 'Mar']
                    values = sort_data.map((item) =>
                        parseInt(item.quantity_reserved),
                    ); // เช่น [10, 20, 30]
                    this.chartOptions.yAxis.title.text = "";
                }

                // อัปเดต chartOptions
                this.chartOptions.xAxis.categories = categories;
                this.chartOptions.series[0].data = values;
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
    },
};
</script>
