<template>
    <div class="top-banner">
        <div>
            <div style="display: flex; align-items: flex-end">
                <img style="height: 60px" src="../../assets/rb_7542.png" />
                <h2 style="margin-left: 10px">
                    รายได้จากการขายสินค้าและบริการของชุมชน
                </h2>
            </div>
            <div class="line_blue"></div>
            <ColumnChart />
            <br />
            <div class="row">
                <div class="col-md-12 py_5px">
                    <div class="chart" style="margin-top: 0">
                        <h3 style="margin-bottom: 10px">
                            รายการขายผลิตภัณฑ์
                        </h3>
                        <div class="table-container">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>ลำดับที่</th>
                                        <th>รายการสินค้า</th>
                                        <th>จํานวน</th>
                                        <th>ราคาต่อหน่วย</th>
                                        <th>จำนวนเงิน</th>
                                        <th>ชุมชน</th>
                                        <th>ผู้ประสานงาน</th>
                                        <th>ที่อยู่</th>
                                        <th>เบอร์โทรศัพท์</th>
                                        <th>วิธีการชำระเงิน</th>
                                        <th>ชื่อลูกค้า</th>
                                        <th>ที่อยู่ลูกค้า</th>
                                        <th>เบอร์โทรศัพท์</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in tableData" :key="index">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.product_name }}</td>
                                        <td>{{ item.quantity }}</td>
                                        <td>{{ item.price_for_one }}</td>
                                        <td>{{ item.total_price }}</td>
                                        <td>{{ item.community }}</td>
                                        <td>{{ item.community_contact }}</td>
                                        <td>{{ item.community_address }}</td>
                                        <td>{{ item.community_telephone }}</td>
                                        <td>{{ item.payment_method }}</td>
                                        <td>{{ item.payment_fullname }}</td>
                                        <td>{{ item.payment_address }}</td>
                                        <td>{{ item.payment_telephone }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 py_5px">
                    <div class="chart">
                        <h3 style="margin-bottom: 10px">กิจกรรมชุมชน</h3>
                        <BarChart type_chart="active" />
                    </div>
                </div>
                <div class="col-md-4 py_5px">
                    <div class="chart">
                        <h3 style="margin-bottom: 10px">
                            เส้นทางการท่องเที่ยว
                        </h3>
                        <BarChart type_chart="route" />
                    </div>
                </div>
                <div class="col-md-4 py_5px">
                    <div class="chart">
                        <h3 style="margin-bottom: 10px">
                            โปรแกรมการท่องเที่ยว
                        </h3>
                        <BarChart type_chart="program" />
                    </div>
                </div>
                <div class="col-md-4 py_5px">
                    <div class="chart">
                        <h3 style="margin-bottom: 10px">ไกด์ท้องถิ่น</h3>
                        <BarChart type_chart="guide" />
                    </div>
                </div>
                <div class="col-md-4 py_5px">
                    <div class="chart">
                        <h3 style="margin-bottom: 10px">ที่พัก</h3>
                        <BarChart type_chart="hotel" />
                    </div>
                </div>
                <div class="col-md-4 py_5px">
                    <div class="chart">
                        <h3 style="margin-bottom: 10px">ร้านอาหาร</h3>
                        <BarChart type_chart="restaurant" />
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 2rem">
            <div style="display: flex; align-items: flex-end">
                <img style="height: 80px" src="../../assets/rb_256.png" />
                <h2 style="margin-left: 10px">
                    ข้อมูลพฤติกรรมของนักท่องเที่ยว
                </h2>
            </div>
            <div class="line_blue"></div>
            <div class="row">
                <div class="col-md-3 py_5px">
                    <div class="chart">
                        <h3 style="margin-bottom: 10px">
                            ความสนใจสินค้า (คลิ๊ก)
                        </h3>
                        <BarChart type_chart="BehaviorProductCommunity" />
                    </div>
                </div>
                <div class="col-md-3 py_5px">
                    <div class="chart">
                        <h3 style="margin-bottom: 10px">
                            ความต้องการสินค้า (ซื้อหรือจอง)
                        </h3>
                        <BarChart type_chart="Reservation" />
                    </div>
                </div>
                <div class="col-md-3 py_5px">
                    <div class="chart">
                        <h3 style="margin-bottom: 10px">
                            ความสนใจในบริการ (คลิ๊ก)
                        </h3>
                        <BarChart type_chart="service" />
                    </div>
                </div>
                <div class="col-md-3 py_5px">
                    <div class="chart">
                        <h3 style="margin-bottom: 10px">
                            ความต้องการในบริการ (ซื้อหรือจอง)
                        </h3>
                        <BarChart type_chart="Reservation_service" />
                    </div>
                </div>
                <div class="col-md-12 py_5px">
                    <div class="chart">
                        <h3 style="margin-bottom: 10px">
                            การเข้าถึงสินค้าและบริการของชุมชน
                        </h3>
                        <MapComponent />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ColumnChart from "./Chart/ColumnChart.vue";
import BarChart from "./Chart/BarChart.vue";
import MapComponent from "./Chart/MapComponent.vue";
import axios from "axios";
export default {
    name: "CommunityDashboardPage",
    components: {
        ColumnChart: ColumnChart,
        BarChart: BarChart,
        MapComponent: MapComponent,
    },
    data() {
        return {
            tableData: [],
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                const community_id = localStorage.getItem("community_id");
                const role_id = localStorage.getItem("role_id");
                const apiData = [];
                if (role_id == 1) {
                    const res = await axios.get(
                        "https://openapi.kims-rmuti.com/api/SaleTable"
                    );
                    apiData.push(...res.data);
                } else {
                    const res = await axios.get(
                        `https://openapi.kims-rmuti.com/api/SaleTable?community=${community_id}`
                    );
                    apiData.push(...res.data);
                }
                this.tableData = apiData.map((item) => ({
                    product_name: item.product_name || "ไม่ระบุ",
                    quantity: item.product_quantity || 0,
                    price_for_one: item.price_for_one || 0,
                    total_price: item.total_price || 0,
                    community: item.community_name || "ไม่ระบุ",
                    community_contact: item.community_contact || "ไม่ระบุ",
                    community_address: item.community_address || "ไม่ระบุ",
                    community_telephone: item.community_telephone || "ไม่ระบุ",
                    payment_method: item.payment_method || "ไม่ระบุ",
                    payment_fullname: item.payment_fullname || "ไม่ระบุ",
                    payment_address: item.payment_address || "ไม่ระบุ",
                    payment_telephone: item.payment_telephone || "ไม่ระบุ",
                }));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
    },
    
};
</script>

<style scoped>
.top-banner {
    position: relative;
    background-color: #f9f9f9;
    padding: 40px;
}
.line_blue {
    margin-top: 13px !important;
    margin-bottom: 20px !important;
    border: 0 !important;
    border-top: 2px solid #206ab0 !important;
}
h3 {
    font-size: 20px;
    font-weight: 500;
    text-align: start;
}

.text_data {
    margin-top: 1rem;
    font-size: 34px;
    font-weight: 600;
    text-align: center;
}

.label_box {
    display: flex;
    background-color: #ffffff;
    padding: 15px 25px;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    box-shadow: 1px 1px 5px #e3e3e3;
}
.label_box p {
    font-size: 16px;
}

.chart {
    margin-top: 10px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #e3e3e3;
}

.py_5px {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

iframe {
    width: 100%;
    height: 800px;
    border: none;
}

.tab_active {
    background-color: #e9eef4 !important;
    color: rgb(32, 106, 176) !important;
    padding: 13px !important;
    font-size: 20px !important;
    border-top: 2px solid !important;
    border-left: 2px solid !important;
    border-radius: 5px 0 0 0 !important;
}

.tap_notactive {
    background-color: #206ab0;
    color: #fff;
    padding: 15px;
    padding-bottom: 13px;
    font-size: 20px;
    border-radius: 0 5px 0 0;
}

.center {
    text-align: center;
}

.sidebar-block {
    padding-bottom: 20px;
    border-bottom: 1px solid #ededed;
    margin-bottom: 20px;
}

.bg_block {
    background-color: #000000;
}

.padd-90-90 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.col-lg-12 {
    padding-right: 25px;
    padding-left: 45px;
}

.popular-img h4 {
    font-weight: 500;
    letter-spacing: 0px;
    padding-bottom: 10px;
    text-shadow: 1px 1px 1px black;
}

.popular-img .vertical-bottom {
    padding: 0px 0px 6px 6px;
    /* text-align:center; */
    backdrop-filter: blur(1px);
}

.c-button.small {
    font-size: 12px;
    padding: 8px 14px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

.c-button.small span {
    font-weight: 500;
}

.imageSizss {
    width: 100%;
    height: 250px;
}

.img-Commu {
    float: left;
}

.borderCommunity {
    border: 10px solid red;
    padding: 5px;
    width: 100%;
    height: 250px;
    background-color: black;
}

.ppcont {
    width: 1000px;
    height: 600px;
}

#text-spac-page-covid {
    letter-spacing: 0;
}

.imgActivity {
    width: 150px;
    height: 100px;
    text-align: center;
}

.pulse {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 15px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
}

.pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -16px 0 0 -13px;
    animation: pulsate 2.5s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    background: rgba(94, 190, 255, 0.5);
    box-shadow: 0 0 1px 2px #2d99d3;
    animation-delay: 1.1s;
}

.simple-tab .tab-info {
    border: 1px solid #e9eef4;
    margin-bottom: -30px;
    padding: 14px 14px;
}

.padd-90 {
    padding-bottom: 0px;
}

.grid-content.block-content .hotel-item.style-10 {
    padding-bottom: 0px;
}

.grid-content {
    margin-bottom: 0px;
}

.grid-content .hotel-item {
    margin-bottom: 0px;
}

.detail-wrapper {
    padding: 0px;
    margin-top: -30px;
}

.right-sidebar {
    object-fit: cover;
}

/*-----------scrollll----------------- */
/*
 *  STYLE 1
 */

.scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff00;
}

.scrollbar::-webkit-scrollbar {
    width: 8px;
    background-color: #ffffff00;
}

.scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background-color: rgb(202, 202, 202);
}

.title_page {
    float: left;
    padding-top: 15px;
    padding-bottom: 15px;
    background: rgba(255, 255, 255, 0.696);
    border-radius: 25px;
}

.scrollbar {
    height: 70vh;
    overflow-y: scroll;
    /* padding: 40px 40px 40px 40px; */
    /* box-shadow: inset 1px 1px 12px #dddddd; */
}

.hotel-item {
    position: relative;
    width: 100%;

    display: inline-block;
    box-shadow: 0px 0px 0px white;
}

.input-style-1.b-50 input {
    font-size: 14px;
    height: 42px;
    padding: 0px 12px 0px 21px;
    color: #000000;
}

@media screen and (max-width: 600px) {
    .col-md-8 {
        padding-bottom: 40px;
    }

    .col-lg-12 {
        padding-right: 15px;
        padding-left: 15px;
    }
}
</style>
