<template>
    <div>
        <div id="map" style="height: 800px; position: relative;"></div>
        <div id="legend" class="map-legend"></div>
    </div>
</template>

<script>
import L from "leaflet";
import "leaflet.markercluster";

import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import axios from "axios";

export default {
    name: "MapComponent",
    mounted() {
        this.initializeMap();
    },
    methods: {
        initializeMap() {
            // Initialize the map
            this.map = L.map("map").setView([13.736717, 100.523186], 5);

            // Add OpenStreetMap tile layer
            L.tileLayer(
                "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                {
                    attribution:
                        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                },
            ).addTo(this.map);

            // Initialize marker cluster group
            this.markerClusterGroup = L.markerClusterGroup();
            this.map.addLayer(this.markerClusterGroup);

            // Load data from multiple APIs
            this.loadAPIs();
        },

        async loadAPIs() {
            const community_id = localStorage.getItem("community_id");
            const role_id = localStorage.getItem("role_id");
            const apis = [
                {
                    url: "https://elasticapi.kims-rmuti.com/peechang*/_search?size=10000",
                    headers: {
                        Authorization:
                            "ApiKey cWlFc0RaQUJHOGRmaTdiLTA2cTU6V2I0VVFwaHJSRUtoOHI0TWZmNlBKZw==",
                    },
                    latField: "_source.customValue.location.lat",
                    lonField: "_source.customValue.location.lon",
                    nameField: "_source.customValue.serviceName",
                    color: "#EB5B00", // Marker color for this API
                    label: "ยอดการเข้าถึง", // Legend label
                },
                {
                    
                    url: `https://openapi.kims-rmuti.com/api/activitycom${role_id === "1" ? "" : `?community=${community_id}`}`,
                    headers: null,
                    latField: "activity_latitude",
                    lonField: "activity_longitude",
                    nameField: "activity_name",
                    color: "#FFB200", // Marker color for this API
                    label: "กิจกรรมท่องเที่ยว", // Legend label
                },
                {
                    url: `https://openapi.kims-rmuti.com/api/ProgramTour${role_id === "1" ? "" : `?community=${community_id}`}`,
                    headers: null,
                    latField: "community_latitude",
                    lonField: "community_longitude",
                    nameField: "activity_name",
                    color: "#B60071", // Marker color for this API
                    label: "โปรแกรมท่องเที่ยว", // Legend label
                },
                {
                    url: `https://openapi.kims-rmuti.com/api/hotel${role_id === "1" ? "" : `?community=${community_id}`}`,
                    headers: null,
                    latField: "hotel_latitude",
                    lonField: "hotel_longitude",
                    nameField: "hotel_name",
                    color: "#FF4C4C", // Marker color for this API
                    label: "ที่พัก", // Legend label
                },
                {
                    url: `https://openapi.kims-rmuti.com/api/restaurant${role_id === "1" ? "" : `?community=${community_id}`}`,
                    headers: null,
                    latField: "restaurant_latitude",
                    lonField: "restaurant_longitude",
                    nameField: "restaurant_name",
                    color: "#4DA6FF", // Marker color for this API
                    label: "ร้านอาหาร", // Legend label
                },
               

            ];

            if (role_id !== 1) {
                apis.push( {
                    url: `https://api.trinitytrip.com/api/community-tourism/communityDetail/${community_id}`,
                    headers: null,
                    latField: "community_latitude",
                    lonField: "community_longitude",
                    nameField: "community_name",
                    imageMarker: require("@/assets/rb_2249.png"), // Path to the image marker
                    excludeFromLegend: true, // Exclude this API from the legend
                    excludeFromCluster: true, // Exclude markers from the cluster
                })}

            this.addLegend(apis); // Add legend box

            for (const api of apis) {
                await this.fetchAndAddMarkers(api);
            }
        },

        async fetchAndAddMarkers(apiConfig) {
            try {
                const response = await axios.get(apiConfig.url, {
                    headers: apiConfig.headers || {},
                });

                const records = response?.data?.data || response?.data?.hits?.hits || response?.data || []; // Extract the `data` array

                const uniqueLocations = new Set(); // Track unique lat/lon pairs

                records.forEach((item) => {
                    const lat = this.extractField(item, apiConfig.latField);
                    const lon = this.extractField(item, apiConfig.lonField);
                    const name = this.extractField(item, apiConfig.nameField) || "Unknown";

                    if (lat !== undefined && lon !== undefined) {
                        const locationKey = `${lat},${lon}`; // Create a unique key for each location
                        if (!uniqueLocations.has(locationKey)) {
                            uniqueLocations.add(locationKey);

                            if (apiConfig.excludeFromCluster) {
                                // Add standalone marker for this API
                                this.addStandaloneMarker(lat, lon, name, apiConfig.imageMarker);
                            } else {
                                // Add marker to the cluster for other APIs
                                this.addMarker(lat, lon, apiConfig.color, name);
                            }
                        }
                    } else {
                        console.warn("Invalid location data:", item);
                    }
                });
            } catch (error) {
                console.error(`Error fetching data from ${apiConfig.url}:`, error);
            }
        },

        addStandaloneMarker(lat, lon, name, imageMarker) {
            const icon = L.icon({
                iconUrl: imageMarker,
                iconSize: [20, 30], // Adjust size as needed
                iconAnchor: [15, 30], // Anchor at the bottom center of the icon
                popupAnchor: [0, -30], // Popup appears above the marker
            });

            const marker = L.marker([lat, lon], { icon });
            marker.bindPopup(`<b>${name}</b>`); // Add popup with the name
            marker.addTo(this.map); // Add directly to the map, not the cluster
        },

        extractField(obj, fieldPath) {
            // Safely extract nested fields (e.g., "_source.customValue.location.lat")
            return fieldPath.split(".").reduce((acc, key) => acc?.[key], obj);
        },

        addMarker(lat, lon, color, name, useLeafletMarker = false) {
            let marker;
            if (useLeafletMarker) {
                // Use Leaflet's default red marker
                marker = L.marker([lat, lon]);
            } else {
                // Create a custom marker icon with the specified color
                const markerIcon = L.divIcon({
                    className: "custom-marker", // Custom class for styling
                    html: `<div style="background-color: ${color}; width: 20px; height: 20px; border-radius: 50%;"></div>`,
                    iconSize: [20, 20],
                    iconAnchor: [10, 10],
                });
                marker = L.marker([lat, lon], { icon: markerIcon });
            }

            marker.bindPopup(`<b>${name}</b>`); // Add popup with the name
            this.markerClusterGroup.addLayer(marker);
        },

        addLegend(apis) {
            const legend = document.getElementById("legend");
            legend.innerHTML = ""; // Clear existing legend

            apis.forEach((api) => {
                const legendItem = document.createElement("div");
                legendItem.style.display = "flex";
                legendItem.style.alignItems = "center";
                legendItem.style.marginBottom = "5px";

                const colorBox = document.createElement("div");
                colorBox.style.backgroundColor = api.color;
                colorBox.style.width = "20px";
                colorBox.style.height = "20px";
                colorBox.style.borderRadius = "50%";
                colorBox.style.marginRight = "10px";

                const label = document.createElement("span");
                label.textContent = api.label;

                legendItem.appendChild(colorBox);
                legendItem.appendChild(label);
                legend.appendChild(legendItem);
            });
        },
    },
};
</script>

<style>
.map-legend {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    z-index: 1000;
}
</style>
