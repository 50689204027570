import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLazyLoad from "vue3-lazyload";
import BackToTop from "vue-backtotop";
import VueSocialSharing from "vue-social-sharing";
import Notifications from "@kyvg/vue3-notification";
import analyticsPlugin from "./plugins/analytics";
import CookieConsentVue from "./CookieConsentVue.js";
//import analyticsPlugin from "./plugins/analytics.js";
import { setupCalendar } from "v-calendar";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
// eslint-disable-next-line no-unused-vars
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import HighchartsVue from "highcharts-vue";
library.add(faChevronLeft);

createApp(App)
    .use(Notifications)
    .use(store)
    .use(router)
    .use(VueLazyLoad)
    .use(BackToTop)
    .use(VueSocialSharing)
    .use(analyticsPlugin)
    .use(CookieConsentVue)
    .use(setupCalendar, {})
    .use(HighchartsVue)
    .mixin({
        methods: {
            getServiceData() {
                // ตรวจสอบว่ามี method getServiceInfo ใน component หรือไม่
                if (typeof this.getServiceInfo === "function") {
                    return this.getServiceInfo();
                }
                // ถ้าไม่มี ให้ใช้ logic เดิม
                return {
                    serviceId: this.serviceData
                        ? this.serviceData[0]?.id
                        : null,
                    serviceName: this.serviceData
                        ? this.serviceData[0]?.name
                        : null,
                };
            },
            logEvent(event, eventType) {
                const serviceData = this.getServiceData();
                this.$logClickEvent(
                    {
                        target: {
                            tagName: eventType,
                            textContent: event.textContent || document.title,
                        },
                    },
                    serviceData,
                );
            },
            handleGlobalClick(event) {
                this.logEvent(event, "CLICK");
            },
        },
        created() {
            this.logEvent({ textContent: document.title }, "PAGE_VIEW");
        },
        mounted() {
            document.addEventListener("click", this.handleGlobalClick);
            if (this.$router) {
                this.$router.afterEach((to) => {
                    this.logEvent(
                        { textContent: to.name || to.path },
                        "ROUTE_CHANGE",
                    );
                });
            }
        },
        beforeUnmount() {
            document.removeEventListener("click", this.handleGlobalClick);
        },
    })

    .mount("#app");
